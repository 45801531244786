import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Title from '../Title';
import { Link } from 'react-router-dom';
import '../Home.css';
import '../../../fonts.css';
import { useState } from 'react';
import './terms.css'
import i18n from "i18next";


const StyledBody = styled.p`
  font-family: 'JohnsonText-Regular', sans-serif;
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
`;
const CustomH2 = styled.h2`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: black;
`;
function TermsOfUse() {
  const { t } = useTranslation();
  const [htmlContent, setHtmlContent] = useState('');
  

  const linkColor = 'var(--bs-link-color)';
  const applyLinkStyles = (htmlContent) => {
    return htmlContent.replace(
      /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>(.*?)<\/a>/g,
      `<a style="color: ${linkColor}; text-decoration: underline;" href="$1"$2>$3</a>`,
    );
  };
  React.useEffect(() => {
    const language = i18n.language;
    const fetchHtmlContent = async () => {
      const response = await fetch(`terms/${language}.html`);
      // const text = await response.text();
      // utf-8 encoding is not working properly, so we need to convert it to base64
      const text = await response.text();
      setHtmlContent(applyLinkStyles(text));
    };

    fetchHtmlContent();
  }, []);
  const padding = '16px 0px 16px 0px';
  return (
    <div className="legal-container">
      <Stack direction={'row'}>
        <Link to="/">
          <img
            src="images/ep_back.png"
            width={'40px'}
            height={'40px'}
            alt={t("termsOfUse.img")}
            style={{ paddingRight: '8px' }}
          />
        </Link>
        <Title title={t('footer.termsOfUse')} titlePaddingStyle={padding}></Title>
      </Stack>
      <StyledBody>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </StyledBody>
    </div>
  );
}
export default TermsOfUse;
