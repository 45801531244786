export const PRIVACY_POLICY_DATA = [
  {
    country: "GLOBAL",
    region: "Janssen Global",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/",
      },
      {
        language: "ESPAÑOL",
        link: "https://www.janssen.com/es",
      },
      {
        language: "FRANÇAIS",
        link: "https://www.janssen.com/fr",
      },
      {
        language: "Português (BR)",
        link: "https://www.janssen.com/pt",
      },
      {
        language: "Italiano",
        link: "https://www.janssen.com/it",
      },
      {
        language: "한국어",
        link: "https://www.janssen.com/ko",
      },
      {
        language: "РУССКИЙ",
        link: "https://www.janssen.com/ru",
      },
    ],
  },
  {
    country: "Asia Pacific (Regional)",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/apac/",
      },
    ],
    isRegional: true,
  },
  {
    country: "China",
    region: "Asia Pacific",
    languages: [
      {
        language: "中文",
        link: "https://www.xian-janssen.com.cn/privacy-policy",
      },
    ],
  },
  {
    country: "India",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/india/privacy-policy",
      },
    ],
  },
  {
    country: "Singapore",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/apac/privacy-policy",
      },
    ],
  },
  {
    country: "South Korea",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/korea/etc/privacy-policy",
      },
    ],
  },
  {
    country: "Taiwan",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/apac/privacy-policy",
      },
    ],
  },
  {
    country: "Thailand",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/apac/privacy-policy",
      },
    ],
  },
  {
    country: "EMEA (Regional)",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/emea/",
      },
    ],
    isRegional: true,
  },
  {
    country: "Austria",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DEUTSCH",
        link: "https://static.janssen-emea.com/sites/default/files/Austria/Legal%20Docs/PrivacyPolicy.html?tracked&link=CookieInformation_v2.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Belgium",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/BE_en%20privacy.html?link=BENE_en_CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
      {
        language: "FRANÇAIS",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/BE_fr%20privacy.html?link=BENE_fr%20CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
      {
        language: "NEDERLANDS",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/BE_nl%20privacy.html?link=BENE_nl_CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Bulgaria",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "БЪЛГАРСКИ",
        link: "https://www.janssen.com/bulgaria/privacy-policy",
      },
    ],
  },
  {
    country: "Croatia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "HRVATSKI",
        link: "https://www.janssen.com/croatia/izjava-o-privatnosti",
      },
    ],
  },
  {
    country: "Cyprus",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/emea/privacy-policy",
      },
    ],
  },
  {
    country: "Czech Republic",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ČEŠTINA",
        link: "https://www.janssen.com/czech/footer/ochrana-soukromi",
      },
    ],
  },
  {
    country: "Denmark",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DANSK",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/DK_Privacy-NEW-Sept2020.html?tracked=&link=DK_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Estonia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "EESTI",
        link: "https://www.janssen.com/estonia/privacy-policy",
      },
    ],
  },
  {
    country: "Finland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SUOMI",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/FI_Policy-NEW-Sept2020.html?tracked=&link=FI_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "France",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "FRANÇAIS",
        link: "https://static.janssen-emea.com/sites/default/files/France/uploads/Mentions%20l%C3%A9gales/Janssen/Privacy_FR.HTML?link=CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Germany",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DEUTSCH",
        link: "https://static.janssen-emea.com/sites/default/files/Germany/uploads/Legal/PrivacyPolicy.html?link=CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Greece",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ΕΛΛΗΝΙΚΑ",
        link: "https://www.janssen.com/greece/privacy_policy",
      },
    ],
  },
  {
    country: "Hungary",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "MAGYAR",
        link: "https://www.janssen.com/hungary/privacy_policy",
      },
    ],
  },
  {
    country: "Ireland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/ireland/privacy-policy",
      },
    ],
  },
  {
    country: "Italy",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ITALIANO",
        link: "https://www.janssen.com/italy/privacy-policy",
      },
    ],
  },
  {
    country: "Latvia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "LATVIEŠU",
        link: "https://www.janssen.com/latvia/privacy-policy",
      },
    ],
  },
  {
    country: "Lithuania",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "LIETUVIŲ",
        link: "https://www.janssen.com/lithuania/privatumo-politika",
      },
    ],
  },
  {
    country: "Luxemburg",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/emea/privacy-policy",
      },
    ],
  },
  {
    country: "Malta",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/emea/privacy-policy",
      },
    ],
  },
  {
    country: "Netherlands",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "NEDERLANDS",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/NL_nl%20privacy.html?link=BENE_nl_CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Norway",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "NORSK",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/NO_Policy-NEW-Sept2020.html?tracked=&link=NO_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Poland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "POLSKI",
        link: "https://www.janssen.com/poland/polityka-prywatnosci",
      },
    ],
  },
  {
    country: "Portugal",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "PORTUGUÊS",
        link: "https://www.janssen.com/portugal/politica-de-privacidade",
      },
    ],
  },
  {
    country: "Romania",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ROMÂNĂ",
        link: "https://www.janssen.com/romania/politica-de-confidentialitate",
      },
    ],
  },
  {
    country: "Slovakia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SLOVENČINA",
        link: "https://www.janssen.com/slovakia/footer/ochrana-sukromia",
      },
    ],
  },
  {
    country: "Slovenia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SLOVENŠČINA",
        link: "https://www.janssen.com/slovenia/zasebnost",
      },
    ],
  },
  {
    country: "Spain",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ESPAÑOL",
        link: "https://www.janssen.com/spain/politica-privacidad",
      },
    ],
  },
  {
    country: "Sweden",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SVENSKA",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/SE_Policy-NEW-Sept2020.html?tracked=&link=SE_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Turkey",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "TÜRKÇE",
        link: "https://www.janssen.com/turkey/gizlilik",
      },
    ],
  },
  {
    country: "United Kingdom",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/uk/privacy-policy",
      },
    ],
  },
  {
    country: "United States of America",
    region: "North America",
    languages: [
      {
        language: "ENGLISH",
        link: "https://www.janssen.com/us/privacy-policy",
      },
    ],
  },
];
