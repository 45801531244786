import React from 'react';
import styled from '@emotion/styled';

const StyledTitle = styled.h1`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-weight: 700;
  font-size: ${(props) => props.fontSize || '32px'};
  color: red;
  margin: 0;
`;
function Title({ title, isMobile, titlePaddingStyle }) {
  return isMobile ? (
    <StyledTitle fontSize="18px" style={{ padding: titlePaddingStyle }}>
      {title}
    </StyledTitle>
  ) : (
    <StyledTitle>{title}</StyledTitle>
  );
}
export default Title;
